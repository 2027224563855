import React, {
    useEffect,
    useRef,
    useState
} from 'react';

const Modal = (props) => {
    const modalContainerRef = useRef();
    const name = props.name || "Mailing List";
    const escListener = (e) => {
        if (e.keyCode === 27) {
            props.toggleModal(false);
        }
    }

    useEffect(() => {
        if (modalContainerRef.current) {
            setTimeout(() => {
                modalContainerRef?.current?.focus();
            }, 1000);
        }
    }, [modalContainerRef]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("keyup", escListener, false);
        }
        return function cleanup() {
            window.removeEventListener("keyup", escListener, false);
        };
    }, []);

    return (
            <div className="modalWrapper">
                <div className="modal-modal-dialog"> 
                    <section className="sc-modal sc-settings">
                        <div ref={modalContainerRef} tabIndex={0} role="dialog" className="sc-modal-pane sc-settings-pane">
                            <button className="sc-icon-button sc-icon-button--icon sc-notification-close-button close-modal-dialog" aria-label={`Close ${name}`} onClick={() => { props.toggleModal(false) }} >
                                <i className="sc-icon-button__icon sc-button__icon--close" />
                            </button>
                            <div className="modal-child-container">
                                <div className="signup-container">
                                    <div className="formkit-background"></div>
                                    {
                                        !props.formSubmitted ?
                                        <div className="formkit-column">
                                            <div className="formkit-header notranslate">
                                                <h2>
                                                    <span>
                                                        <span>
                                                            <span>Get a free chapter of my new book</span>
                                                        </span>
                                                    </span>
                                                </h2>
                                            </div>
                                            <div className="signup-modal-form">
                                                {props.children}
                                            </div>
                                            <div className="seva-fields formkit-fields">
                                                <div className="formkit-disclaimer notranslate">
                                                    <p className="text-center">
                                                        <span >
                                                            <span>
                                                                <span>
                                                                    I respect your privacy. You will not be spammed. Your email address will only be used to contact you regarding my upcoming books and giveaways.
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </p>
                                                    <p className="text-center">
                                                        <span >
                                                            <span>
                                                                <span>
                                                                    Unsubscribe at any time.
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="formkit-column">
                                            <div className="formkit-header notranslate">
                                                <h2>
                                                    <span>
                                                        <span>
                                                            <span>Success!</span>
                                                        </span>
                                                    </span>
                                                </h2>
                                            </div>
                                            <div className="signup-modal-form">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" height="60px" viewBox="0 0 472.6 493.4" xmlSpace="preserve">
                                                <path d="M466,279.7l-54.9,36.1C384,379.9,320.6,425,246.7,425c-98.3,0-178.2-80-178.2-178.2s80-178.2,178.2-178.2  c46.8,0,91.7,18.4,124.9,51.3L246.9,250.3l-65.3-68.8l-49.7,47.2l114.8,121l164.4-171.9c0,0,0,0.1,0.1,0.1l50.5-52.6  c-11-19.4-24.6-37.3-40.4-53c-96.4-96.4-252.6-96.4-349,0s-96.4,252.6,0,349c96.6,96.4,253,96.2,349.4-0.4  c21.4-21.4,38.7-46.6,50.9-74.3L466,279.7z" fill="green"/>
                                            </svg>
                                            </div>
                                            <div className="seva-fields formkit-fields">
                                                <div className="formkit-disclaimer notranslate success-body">
                                                    <p className="text-center">
                                                        <span >
                                                            <span>
                                                                <span>
                                                                    You have been successfully added to the mailing list. Please click the link in your email* to download your FREE chapter of Everlasting Rain.
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </p>
                                                    <p className="text-center disclaimer">
                                                        <span >
                                                            <span>
                                                                <span>
                                                                    *If you can't see the email in your inbox, check your junk folder and remember to mark it as "not junk" so you don't miss future emails.
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
    )
}

export default Modal;