/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import map from "../assets/images/NaRevas.png";
import mapJS from "../API/ImageMapResize.js";

function Novel() {
  const [showDetails, setShowDetails] = useState(null);
  const [showInfo, setShowInfo] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);

  const hoverOver = (placename) => {
    setShowDetails(placename);
  }

  const toggleIsland = (placename) => {
    placename === showInfo ? setShowInfo(null) : setShowInfo(placename);
  }

  const hoverOut = () => {
    setShowDetails(null);
  }

  useEffect(() => {
    const mobile = window?.innerWidth <= 700;
    setIsMobile(mobile);
  }, []);

  const toggleMap = () => {
    setMapOpen(!mapOpen);
  }

  const getContent = () => {
    if(showDetails){
      return islandDetails.map(data => {
        if (data.name === showDetails){
          return data.string;
        }
      });
    }
  }

  const islandDetails = [
    {
      name: "Xevería",
      text: "Xevería is a desert world thousands of miles from the rest of Na’Revas with its own language and a religious culture dedicated to breathing life back into the defunct technology of the Ancestors. With a warm climate, most shield from the sun beneath homes built from various metals. Sorcerer children taken from their parents are put under the protection of Abbots or Abbesses, confined to living in an abbey to protect others from their powers.",
      string : ( 
            <div className="map-info-container">
              <h1>Xevería</h1>
              <p>Xevería is a desert world thousands of miles from the rest of Na’Revas with its own language and a religious culture dedicated to breathing life back into the defunct technology of the Ancestors. With a warm climate, most shield from the sun beneath homes built from various metals. Sorcerer children taken from their parents are put under the protection of Abbots or Abbesses, confined to living in an abbey to protect others from their powers.</p>
           </div>
           )
    },{
      name: "Ortivana",
      text: "When the Scours burned a sorcerer alive in 4111, that sorcerer cursed Ortivana with her last breath, turning the sky amethyst and causing toxic Everlasting Rain to fall. Since then, Ortivana Island has been locked in civil war with the Sovereigns, who overthrew the Scours a few years later. The Freebooter Rebels have been challenging the Tripartite leadership at every turn, hoping to win Ortivana’s independence, but the walled capital of Ortiva City remains strong, segregating the rich from the poor.",
      string: (<div className="map-info-container">
              <h1>Ortivana</h1>
              <p>When the Scours burned a sorcerer alive in 4111, that sorcerer cursed Ortivana with her last breath, turning the sky amethyst and causing toxic Everlasting Rain to fall. Since then, Ortivana Island has been locked in civil war with the Sovereigns, who overthrew the Scours a few years later. The Freebooter Rebels have been challenging the Tripartite leadership at every turn, hoping to win Ortivana’s independence, but the walled capital of Ortiva City remains strong, segregating the rich from the poor.</p>
          </div>
          )
    },{
      name: "Anderan",
      text: "The Scour homeworld and location of Oblivion Tower where Scour-Commandant Trevus imprisons sorcerers and experiments on them. No sorcerer is safe here, and only those with status live in the High District. The rest of the island scrapes to survive in the slums, and those too poor to even live there, reside amongst the skeletal remains of defunct Artificial Intelligence from the Advanced Digital Age. Anderan’s greenery is believed to have been carved by the Goddess herself, with lush green forests overrunning the island, swallowing people up in the dense shrubbery.",
      string: (<div className="map-info-container">
              <h1>Anderan</h1>
              <p>The Scour homeworld and location of Oblivion Tower where Scour-Commandant Trevus imprisons sorcerers and experiments on them. No sorcerer is safe here, and only those with status live in the High District. The rest of the island scrapes to survive in the slums, and those too poor to even live there, reside amongst the skeletal remains of defunct Artificial Intelligence from the Advanced Digital Age. Anderan’s greenery is believed to have been carved by the Goddess herself, with lush green forests overrunning the island, swallowing people up in the dense shrubbery.</p>
          </div>
          )
    },{
      name: "Stonawal",
      text: "The largest island of Na’Revas and home to the Sovereigns and their leader, Altus. Not much is known beyond the mountainous terrain where the population lives in gold-laden, enclosed cities. Altus’ palace overlooks the west of the island and The Neverending Ocean near Sailor’s Creek, but his reach extends throughout Na’Revas, controlling the Cuatro Isles and proving the Sovereigns to be the strongest contingent of the Tripartite.",
      string: (<div className="map-info-container">
              <h1>Stonawal</h1>
              <p>The largest island of Na’Revas and home to the Sovereigns and their leader, Altus. Not much is known beyond the mountainous terrain where the population lives in gold-laden, enclosed cities. Altus’ palace overlooks the west of the island and The Neverending Ocean near Sailor’s Creek, but his reach extends throughout Na’Revas, controlling the Cuatro Isles and proving the Sovereigns to be the strongest contingent of the Tripartite.</p>
          </div>
          )
    },{
      name: "PuertoLibre",
      text: "The ‘Free Port’ is the only island controlled and regulated by the Swashbuckler contingent of the Tripartite. Pirates can dock freely on this tiny island and conduct their business, whether legit or otherwise. Scours and Sovereigns tend to avoid the island completely and fight their battles elsewhere for fear of uniting the divided Sovereigns under one banner. A huge arena on the island lets pirates sort out their grievances internally from crew to crew, but if provoked, each Swashbuckler would undoubtedly put their differences aside to fight a common enemy.",
      string: (<div className="map-info-container">
              <h1>Puerto Libre</h1>
              <p>The ‘Free Port’ is the only island controlled and regulated by the Swashbuckler contingent of the Tripartite. Pirates can dock freely on this tiny island and conduct their business, whether legit or otherwise. Scours and Sovereigns tend to avoid the island completely and fight their battles elsewhere for fear of uniting the divided Sovereigns under one banner. A huge arena on the island lets pirates sort out their grievances internally from crew to crew, but if provoked, each Swashbuckler would undoubtedly put their differences aside to fight a common enemy.</p>
          </div>
          )
    },{
      name: "SailorsCreek",
      text: "There aren’t many who venture near the southern island of Sailor’s Creek, and those who do usually don’t come back. It is unknown whether the Creek is magically controlled, or if nearby waters are just too rough for most ships to pass through.",
      string: (<div className="map-info-container">
              <h1>Sailor’s Creek</h1>
              <p>There aren’t many who venture near the southern island of Sailor’s Creek, and those who do usually don’t come back. It is unknown whether the Creek is magically controlled, or if nearby waters are just too rough for most ships to pass through.</p>
          </div>
          )
    },{
      name: "ArmadaSea",
      text: "Claiming part of The Neverending Ocean as his own, Fleet-Admiral Escudero of the Saristca Armada has his Swashbucklers parading the sea north of the Cuatro Isles, controlling the trade routes. His reach stretches as far as Xevería all the way to Coutoreál in the south-east of Na’Revas. The Saristca Armada is the most powerful faction of Swashbucklers, followed closely by their rivals, the Blue Shield Souzas, who compete for both land and sea space to conduct their business.",
      string: (<div className="map-info-container">
              <h1>Saristca Armada Sea</h1>
              <p>Claiming part of The Neverending Ocean as his own, Fleet-Admiral Escudero of the Saristca Armada has his Swashbucklers parading the sea north of the Cuatro Isles, controlling the trade routes. His reach stretches as far as Xevería all the way to Coutoreál in the south-east of Na’Revas. The Saristca Armada is the most powerful faction of Swashbucklers, followed closely by their rivals, the Blue Shield Souzas, who compete for both land and sea space to conduct their business.</p>
          </div>
          )
    }
  ];

  useEffect(() => {
    console.log(showDetails);
  }, [showDetails])


  useEffect(() => {
    const loadScripts = async () => {
      // await loadScript(mapJS);
      // eslint-disable-next-line no-undef
      imageMapResize();
    }
    loadScripts();

    document.addEventListener("mousemove", function (e) {
      var infoBox = document.getElementById("infoBox");
      if(infoBox){
          if(window.innerWidth > 700){
            // Update the position of the info box based on the cursor's coordinates
            infoBox.style.top = e.clientY + window.scrollY + 10 + "px"; // Add 10px to avoid overlap
            infoBox.style.left = e.clientX + 10 + "px"; // Add 10px to avoid overlap
            infoBox.style.display = "block";
          }else{
            infoBox.style.borderRadius = 0;
            infoBox.style.width = "95%"
            infoBox.style.marginTop = "-30px"
            infoBox.style.display = "block";
            // infoBox.scrollIntoView();
          }
      }
    })

  }, [])


  return (
    <main>
        <div className='home-content3'>
          {
            isMobile ?
              <div class="formkit-testimonial map-mobile-view-quote">
                  <div>
                      <div class="formkit-testimonial_quote">
                          <h1> <strong>By 2523, the world as the Ancestors had known it was destroyed by magic. Everlasting Rain fell where sorcerers tore the sky, and countries were split into tiny islands over the next few millennia creating the Islands of Na'Revas.</strong> </h1>
                      </div>
                  </div>
              </div> :           
              <div class="formkit-testimonial">
                  <div>
                      <div class="formkit-testimonial_quote">
                          <h1> <strong>In the year 2501, sorcery emerged as a human genetic disorder. By 2523, the world as the Ancestors had known it was destroyed by magic. Everlasting Rain fell where sorcerers tore the sky, and countries were split into tiny islands over the next few millennia. A new world power emerged called The Tripartite; a trio of Sovereigns, Scours, and Swashbucklers, ruthlessly competing for rulership of the Islands of Na'Revas.</strong> </h1>
                      </div>
                  </div>
              </div>
          }
        </div>
        <div className="main-mapContainer">
          <div class="banner">
            <div class="parchment">
                <div class="stain"></div>
                <p>The Islands of Na'Revas</p>
                {
                  isMobile ?
                  <p className='islands-subtext'>Click the map and rotate your device</p>:
                  <p className='islands-subtext'>Hover over the islands to find out more</p>
                }
            </div>
          </div>
          {
            isMobile ?
            <div className="mapContainer">
              <img onClick={toggleMap} src={map} className={`map-mobile ${mapOpen ? "map-fullscreen" : ""}`} id="mobile-map" alt="Map of Na'Revas"/>
            </div> :
            <div className="mapContainer">
              {/* mapping generated by https://www.image-map.net/ */}
              <img src={map} usemap="#image-map" alt="Map of Na'Revas"/>

              <map name="image-map">
                  <area className="hoverArea" onMouseOver={() => hoverOver("Xevería")} onMouseOut={hoverOut} alt="Xevería" coords="317,260,162,118" shape="rect" />
                  <area className="hoverArea" onMouseOver={() => hoverOver("Ortivana")} onMouseOut={hoverOut} alt="Ortivana" coords="615,489,558,512,532,556,564,597,667,579,690,522" shape="poly" />
                  <area className="hoverArea" onMouseOver={() => hoverOver("Anderan")} onMouseOut={hoverOut} alt="Anderan" coords="958,545,992,541,1030,552,1056,514,1017,483,994,481,947,470,939,497,956,513,923,550,931,577" shape="poly" />
                  <area className="hoverArea" onMouseOver={() => hoverOver("Stonawal")} onMouseOut={hoverOut} alt="Stonawal" coords="436,816,392,861,1146,863,1125,810,1081,818,1059,802,1026,802,979,757,916,794,901,826,866,842,771,794,716,814,701,767,619,820,576,792,522,820" shape="poly" />
                  <area className="hoverArea" onMouseOver={() => hoverOver("PuertoLibre")} onMouseOut={hoverOut} alt="PuertoLibre" coords="827,708,778,734,821,760,866,745" shape="poly" />
                  <area className="hoverArea" onMouseOver={() => hoverOver("SailorsCreek")} onMouseOut={hoverOut} alt="SailorsCreek" coords="254,781,280,825,319,844,388,803,334,771,313,768" shape="poly" />
                  <area className="hoverArea" onMouseOver={() => hoverOver("ArmadaSea")} onMouseOut={hoverOut} alt="ArmadaSea" coords="383,162,656,248" shape="rect" />
              </map>
            </div>
          }
          {(showDetails && !isMobile) &&
            <div class="info-box" id="infoBox">
                {getContent()}
            </div>
          }
          <div className="home-content2">
            <h2 className="sc-block-heading alignfull has-text-align-left has-text-color updates-h2">
                Explore Na'Revas:
            </h2>
            <div className="map-details-all">
              {
                islandDetails.map(data => (
                  <div className="map-details-collapsable">
                    <h4 tabIndex={0} onKeyDown={(e) => {(e.code === 'Space' || e.code === 'Enter') ? toggleIsland(data.name) : null}} onClick={() => toggleIsland(data.name)}>{data.name}</h4>
                    {
                      showInfo === data.name ?
                      <p>{data.text}</p> : null
                    }
                  </div>
                ))
              }
            </div>
          </div>
      </div>
    </main>
  );
}

export default Novel;
