import React, { useEffect, useState } from 'react';
import ConvertKitForm from 'convertkit-react'
const MY_FORM_ID = 5448126

function SubscribeForm() {

  useEffect(() => {
    if(document.getElementById("ck-first-name")){
      const input1 = document.querySelectorAll("#ck-first-name");
      const input2 = document.querySelectorAll("#ck-email");
      for(let t = 0; t < input1.length; t++){
        input1[t].setAttribute("required", true);
        input2[t].setAttribute("required", true);
      }
    }
  })

  return (
    <section>
        <ConvertKitForm formId={MY_FORM_ID} />
    </section>
  );
}

export default SubscribeForm;
