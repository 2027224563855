import React from 'react';
import preOrder from "../assets/images/pre-order.png"
import quote from "../assets/images/quote.png"
import Updates from '../Components/Updates';

const Home = (props) => {

  return (
    <main>
        <div>
            <div className="sc-block-group alignfull has-background purple-grade-bkg">
                <div className="sc-block-columns alignfull is-layout-flex sc-container-30 purple-grade-bkg2">
                    <div className="sc-block-column is-layout-flow purple-grade-bkg3">
                        <div className="sc-block-group alignfull has-background is-content-justification-center purple-grade-bkg4">
                            <div className="sc-block-group purple-grade-bkg-main">
                                <div className="sc-block-columns alignwide are-vertically-aligned-center is-layout-flex sc-container-21 purple-grade-div1">
                                  <div className="sc-block-column is-vertically-aligned-center purple-grade-div2">
                                      <h1 className="sc-block-heading alignfull has-text-align-left has-white-color has-text-color purple-grade-div4">
                                          Everlasting Rain
                                      </h1>
                                      <p className="has-text-align-left has-white-color has-text-color has-roboto-font-family purple-grade-div3">
                                        AVAILABLE ON AMAZON NOW <br/>
                                        <a href="https://amzn.eu/d/fOnXKxU" target="_blank" rel="noopener noreferrer" title="Click here to visit Amazon.co.uk to pre order Everlasting Rain" className="has-white-color">Everlasting Rain is available here</a>
                                      </p>
                                      <p>
                                          Everlasting Rain, the epic Fantasy/Lesbian Romance novel has been released on Kindle and softback on 7th November 2023 via Amazon. Be kept informed of the release and other exciting news by signing up to the mailing list. By doing so you will also receive a free copy of the prologue and first chapter.
                                      </p>
                                      <p>
                                          Please enjoy a&nbsp;<strong>FREE</strong>&nbsp; preview of the prologue and first
                                          chapter by signing up to my mailing list below. The book is now available on Amazon - <a href="https://amzn.eu/d/fOnXKxU" target="_blank" rel="noopener noreferrer" title="Click here to visit Amazon.co.uk to pre order Everlasting Rain" className="has-white-color">Get your copy here</a>
                                      </p>
                                      <button onClick={() => props.showSignupModal(true)} className="btn-signup">Sign up for your free chapter</button>
                                  </div>

                                  <div className="sc-block-column is-vertically-aligned-center is-content-justification-center is-layout-constrained purple-grade-div5">
                                      <figure className="sc-block-image aligncenter size-large is-resized">
                                      <a href="https://amzn.eu/d/fOnXKxU" target="_blank" rel="noopener noreferrer" title="Click here to visit Amazon.co.uk to pre order Everlasting Rain">
                                          <img decoding="async" src={preOrder} alt="pre-order Everlasting Rain now" className="sc-image-14 pre-order-promo" width="" height="346" sizes="(max-width: 241px) 100vw, 241px" />
                                        </a>
                                      </figure>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='home-content2'>
                <Updates/>
            </div>
            <div className='home-content3'>
                <div class="formkit-testimonial">
                    <div class="formkit-testimonial-mark">
                        <img src={quote} alt="quotation marks" />
                    </div>
                    <div>
                        <div class="formkit-testimonial_quote">
                            <h1>
                                <strong>“I mean, they could have arrested you for anything, couldn’t they? Espionage, theft, betrayal. Why would they choose to label you ‘The Child Killer’ and arrest you for the death of a peasant boy so insignificant to them?”</strong>
                            </h1>
                        </div>
                        <div class="formkit-testimonial_byline">
                            <h1>
                                <strong>Esmeralda Rivera, </strong>
                                <em><strong>Everlasting Rain</strong></em>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
  );
}

export default Home;
