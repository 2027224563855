import React, { useState } from 'react';
import siobhan from "../assets/images/siobhan-new.png";
import Updates from '../Components/Updates';
import siobhanRed from "../assets/images/siobhan-red.png";
import quote from "../assets/images/quote.png";

const About = (props) => {
    const [showInterview, setShowInterview] = useState(false);

    const toggleInterview = (val) => {
        setShowInterview(val)
    }
    
  return (
    <main>
        <div>
            <div className="sc-block-group alignfull has-background purple-grade-bkg">
                <div className="sc-block-columns alignfull is-layout-flex sc-container-30 purple-grade-bkg2">
                    <div className="sc-block-column is-layout-flow purple-grade-bkg3">
                        <div className="sc-block-group alignfull has-background is-content-justification-center purple-grade-bkg4">
                            <div className="sc-block-group purple-grade-bkg-main">
                                <div className="sc-block-columns alignwide are-vertically-aligned-center is-layout-flex sc-container-21 purple-grade-div1">
                                  <div className="sc-block-column is-vertically-aligned-center purple-grade-div2">
                                      <p className="has-text-align-left has-white-color has-text-color has-roboto-font-family purple-grade-div3">
                                          <a href="https://amzn.eu/d/fOnXKxU" target="_blank" rel="noopener noreferrer" title="Click here to visit Amazon.co.uk to pre order Everlasting Rain" className="has-white-color">DEBUT NOVEL - Everlasting Rain is available here</a>
                                      </p>
                                      <h2 className="sc-block-heading alignfull has-text-align-left has-white-color has-text-color purple-grade-h4">
                                        Siobhán O 'Connor
                                      </h2>
                                      <p>
                                        Siobhán O’Connor is an Irish author, based in Belfast. After studying Spanish and History at University of Ulster Coleraine, she lived in Spain where she briefly attended Universidad de Murcia in 2012, before graduating in 2014. Inspired by fantasy and sci-fi roleplaying video games, she created the idea for her debut novel, Everlasting Rain, in which she amalgamated her love of language and culture with fantastical elements and lesbian romance.
                                      </p>
                                      <p>
                                          Please enjoy a&nbsp;<strong>FREE</strong>&nbsp; preview of the prologue and first
                                          chapter by signing up to the mailing list below. The book is now available on Amazon - <a href="https://amzn.eu/d/fOnXKxU" target="_blank" rel="noopener noreferrer" title="Click here to visit Amazon.co.uk to pre order Everlasting Rain" className="has-white-color">Get your copy here</a>
                                      </p>
                                        <button onClick={() => props.showSignupModal(true)} className="btn-signup">Sign up for your free chapter</button>
                                  </div>

                                  <div className="sc-block-column is-vertically-aligned-center is-content-justification-center is-layout-constrained purple-grade-div5">
                                      <figure className="sc-block-image aligncenter size-large is-resized">
                                          <img decoding="async" src={siobhan} alt="Siobhan O'Connor - author" className="sc-image-14" width="" height="346" sizes="(max-width: 241px) 100vw, 241px" />
                                      </figure>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='home-content2'>
                {/* <Updates/> */}
                <h2 className="sc-block-heading alignfull has-text-align-left has-text-color updates-h2">
                    An Interview with Siobhán O’Connor
                </h2>
                <div className="interview-container">
                    <img className='interview-pic' alt="Siobhan O'Connor - author" src={siobhanRed} />
                    <div className='interview-questions-div'>
                        <p><b>Q. When did you decide to become an author?</b></p>
                        <p>I’m not sure I ever decided to be honest, at least not until I had a completed first draft in my hands. I always wrote for my own amusement from an early age, primarily songs, and then once the musical side of things slowed down, I started writing stories. I wrote a lot of fanfiction in the beginning, using previously created characters and writing them within their current arcs. This helped me develop the craft, and soon I started using original ideas, eventually forming what would later become Everlasting Rain.</p>
                        {
                            !showInterview ? 
                            <button className="continue-reading" aria-label='click here to show more interview questions' onClick={() => toggleInterview(true)}>Continue reading...</button>:
                            <>
                                <p><b>Q. How did Everlasting Rain come about?</b></p>
                                <p>Ten years ago, when I was still writing fanfiction, a guy got in touch with me saying he would be interested in creating a video game with me and asked if I had any original ideas. We worked together for a while but eventually it petered out, and I was left with all this original content I had created without a plan for it. It was the bare bones of this that I used to create Everlasting Rain. </p>

                                <p><b>Q. Can you tell us a bit about the book?</b></p>
                                <p>Everlasting Rain is set in a future where artificial intelligence has been eradicated, sending the world back to zero. Some humans suffer from a genetic disorder that manifests itself as sorcery and sorcerers are hunted to death. The main character, Lana Hale, is rescued from prison by Admiral Esmeralda Rivera (a pirate), and the two of them flee Ortivana Island in search of Hale’s sorcerer sister, Cecerie, who has gone missing. Along the way, Hale and Esmeralda become close, but their relationship always has an element of mistrust as both of them have lived difficult lives in the unforgiving, hostile world of Na’Revas. </p>

                                <p><b>Q. You write characters in both Spanish and English languages, and there is an element of this in Everlasting Rain. What made you use both languages in this book?</b></p>
                                <p>I always think there is something lost in translation, so it was important for characters like Esmeralda Rivera in the book to be able to express themselves in their ‘native’ language. Spanish is such a beautiful language that I have dedicated over half my life to studying, so I thought it would be a shame not to use it in my creative work. I’m not as fluent now as maybe I once was, so this was a great way for me to rediscover some of that vocabulary that I had acquired during my degree, living in Murcia. </p>

                                <p><b>Q. What can readers expect from Everlasting Rain?</b></p>
                                <p>A lot of hurt (laughs). It’s a really hostile world that these characters live in, and they are all fighting for something different. Lana Hale fights for what she considers noble causes, like freedom and a right to live without oppression, whereas Esmeralda just lives for herself and her inner circle, trying to live one day at a time. There is an interesting dynamic between the two main characters as they are opposites in a lot of ways. For example, Hale is reluctant to take a life, whereas Esmeralda wouldn’t think twice about killing someone who gets in her way. It was amusing putting these characters in similar situations and seeing how they dealt with it. </p>

                                <p><b>Q. Do you have any advice for other self-published authors out there, or anyone who thinks they might want to write a book?</b></p>
                                <p>Write whatever comes into your head, and write consistently. I’ve met so many people over the years who say they would like to write a book, but the most common theme with all of them is that they either stopped writing or never started in the first place. No one ever writes a perfect first draft (or second draft, or third draft), but as long as you put something down on that page, you’re one step closer to finishing your book. Cutting out the bad parts comes later. Everlasting Rain was 140,000 words when I started and I managed to cut it down to 85,000 for the final draft, so it just proves how much of it was crap. It’s all a learning curve, but as long as you’re persistent, you’ll eventually be able to share your completed story with the world (or keep it secret for your own entertainment. That’s ok too!). </p>

                                <p><b>Q. Do you have anything to say to your readers?</b></p>
                                <p>Just thank you for supporting me and I hope you enjoy it. I’ll be working on a sequel in the new year, so the story doesn’t end with Everlasting Rain. It’s only just begun, so I hope you'll stick with me for the ride. </p>

                                <button className="continue-reading" aria-label='click here to hide the interview questions' onClick={() => toggleInterview(false)}>Hide interview...</button>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className='home-content3'>
                <div class="formkit-testimonial">
                    <div class="formkit-testimonial-mark">
                        <img src={quote} alt="quotation marks" />
                    </div>
                    <div>
                        <div class="formkit-testimonial_quote">
                            <h1>
                                <strong>“She needs an arm around her, not one holding her back because you’re all afraid of what she can do.”</strong>
                            </h1>
                        </div>
                        <div class="formkit-testimonial_byline">
                            <h1>
                                <strong>Esmeralda Rivera, </strong>
                                <em><strong>Everlasting Rain</strong></em>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
  );
}

export default About;
