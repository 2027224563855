import bioPic from "../assets/images/siobhan.png"

const Card = (props) => {

    return (
        <div className="card">
            <div className="card__header">
                <img src={props.image} alt={`${props.title}`} className="card__image" width="600" />
            </div>
            <div className="card__body">
                <h4>{props.title}</h4>
                <p>{props.body}</p>
            </div>
            <div className="card__footer">
                <div className="user">
                    <img src={bioPic} alt="Siobhan O'Connor - author" className="user__image" />
                    <div className="user__info">
                        <h5>Siobhán O'Connor</h5>
                        <small>{props.date}</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card;