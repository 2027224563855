import React, { useState } from 'react';

function Meet() {

  return (
    <main>
        <div>
            Meet Page
        </div>
    </main>
  );
}

export default Meet;
