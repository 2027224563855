import React, { useState } from 'react';

function Privacy() {

  return (
    <main>
      <div className="privacy-main-container">
        <div className="">
          <div className="">
            <h1>Privacy statement</h1>
          </div>
          <div className="top-line-break">
          </div>
        </div>
        <div className="privacy-container">
          <h2>We respect your privacy</h2>
          <p>We are committed to protecting your privacy and this statement outlines the personal details we may collect from you while using our site.</p>
          
          <h2>Collection</h2>
          <p>We collect your personal information in a few different ways. For example, we collect information you send us when joining our mailing list. We also use Google Analytics to gather anonymous usage statistics of the site. If you wish us to remove your details from our systems, please get in touch using the contact information below or use the unsubscribe link in the email you received. To opt-out of Google Analytics please see <a href="https://support.google.com/analytics/answer/181881?hl=en" target="_blank" rel="noopener noreferrer">this Google Support Page.</a></p>
        </div>
        <div className="privacy-container">
          <h2>E-Mail</h2>
          <p>When you e-mail us, or sign up to the mailing list within this site we will use your email address to contact you with relevant information. We will not use your name or email address for any other purpose.</p>
          
          <h2>Sharing your personal information</h2>
          <p>We do not sell or share your information to anyone.</p>
        </div>
      </div>
    </main>
  );
}

export default Privacy;
